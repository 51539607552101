'use client'

import { noop } from 'lodash'
import { createContext } from 'react'

import { AdsPlacementModel } from 'types/models'

import { RegisterPlacementProps } from './types'
import { AdPlatform } from '../../constants'

export type AdsContextType = {
  placements: Array<AdsPlacementModel>
  segments: Record<string, string>
  hasAdBlockerBeenTracked: boolean
  shouldMockAds: boolean
  isAdBlockerUsed: boolean | null
  adBlockerVisitorId: string | null
  setIsAdBlockerUsed: (isAdBlockerUsed: boolean) => void
  setHasAdBlockerBeenTracked: (hasAdBlockerBeenTracked: boolean) => void
  setAdBlockerVisitorId: (adBlockerVisitorId: string) => void
  registerPlacement: (props: RegisterPlacementProps) => void
  generatePlacementId: () => string
  unregisterPlacement: (id: string) => void
  getAdsPlatform: (userAgent: string) => AdPlatform
}

const AdsContext = createContext<AdsContextType>({
  placements: [],
  segments: {},
  hasAdBlockerBeenTracked: false,
  shouldMockAds: false,
  isAdBlockerUsed: null,
  adBlockerVisitorId: null,
  setIsAdBlockerUsed: noop,
  setHasAdBlockerBeenTracked: noop,
  setAdBlockerVisitorId: noop,
  registerPlacement: noop,
  generatePlacementId: () => 'adplacement-1',
  unregisterPlacement: noop,
  getAdsPlatform: () => AdPlatform.Web,
})

export default AdsContext
