import { AdsPlacementModel } from 'types/models'

import { AdKind } from '../../constants'

export function getAdPlacementId(placementConfig: AdsPlacementModel) {
  let code = ''
  const separator = '_'

  if ([AdKind.Rtb, AdKind.Van].includes(placementConfig.kind)) {
    code = [
      placementConfig.countryCode,
      placementConfig.platform,
      placementConfig.page,
      placementConfig.shape,
    ].join(separator)
  }

  if (placementConfig.mediation) {
    code = [code, placementConfig.mediation].join(separator)
  }

  return code
}
